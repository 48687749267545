/* ./src/index.css */
html {
  background: #374151;
}

@tailwind base;
@tailwind components;

button:focus {
  outline: none;
}

.btn {
  @apply flex items-center justify-center whitespace-nowrap text-14 font-medium px-3 h-7 rounded border border-gray-900 text-gray-900 bg-gray-400;
}

.btn:hover {
  @apply bg-gray-300;
}

.btn:active {
  @apply bg-gray-400;
}

.btn:disabled {
  @apply border-gray-400 text-gray-400 bg-gray-400;
}

.icon-btn {
  @apply flex items-center justify-center rounded-lg text-gray-900;
}

.icon-btn:hover {
  @apply bg-gray-900 bg-opacity-25;
}

.icon-btn:active {
  @apply bg-gray-900 bg-opacity-10;
}

.icon-btn:disabled {
  @apply bg-opacity-0 text-gray-400;
}

.text-input {
  @apply flex items-center px-2 h-8 rounded border border-gray-300;
}

.text-input:focus {
  @apply outline-none border-blue-500;
}

@tailwind utilities;
